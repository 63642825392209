/* ==========================================================================
   Loading Styles
   ========================================================================== */

/* Loading container */
.loading {
  background: linear-gradient(
    129deg,
    #c6a1e8 3.83%,
    #50ddf8 38.45%,
    #fdfcee 72.35%
  );
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  z-index: 99999;
  animation: fade 2s normal forwards ease-in-out;
}

/* Loading image */

.loading img {
  background: rgb(255, 255, 255);
  padding: 5px;
  border-radius: 100px;
  width: 80px;
  height: 80px;
}

@keyframes fade {
  100% {
    opacity: 0;
  }

  80% {
    opacity: 1;
  }

  0% {
    opacity: 1;
  }
}
