/* ==========================================================================
   Animations
   ========================================================================== */

/* Animation Item */

.animate-elem {
  transition: all 1s ease;
}

/* Animation Delay */

.animate-delay-01 {
  transition: all 1s 0.1s ease;
}

.animate-delay-02 {
  transition: all 1s 0.2s ease;
}

.animate-delay-03 {
  transition: all 1s 0.3s ease;
}

.animate-delay-04 {
  transition: all 1s 0.4s ease;
}

.animate-delay-05 {
  transition: all 1s 0.5s ease;
}

.animate-delay-06 {
  transition: all 1s 0.6s ease;
}

.animate-delay-07 {
  transition: all 1s 0.7s ease;
}

.animate-delay-08 {
  transition: all 1s 0.8s ease;
}

.animate-delay-09 {
  transition: all 1s 0.9s ease;
}

.animate-delay-10 {
  transition: all 1s 0s ease;
}

.animate-delay-11 {
  transition: all 1s 1s ease;
}

.animate-delay-12 {
  transition: all 1s 1.2s ease;
}

.animate-delay-13 {
  transition: all 1s 3s ease;
}

.animate-delay-14 {
  transition: all 1s 4s ease;
}

.animate-delay-15 {
  transition: all 1s 1.5s ease;
}

.animate-delay-16 {
  transition: all 1s 6s ease;
}

.animate-delay-17 {
  transition: all 1s 1.7s ease;
}

.animate-delay-18 {
  transition: all 1s 8s ease;
}

.animate-delay-19 {
  transition: all 1s 9s ease;
}

.animate-delay-20 {
  transition: all 1s 2s ease;
}

.animate-delay-21 {
  transition: all 1s 2.1s ease;
}

.animate-delay-22 {
  transition: all 1s 2.2s ease;
}
.animate-delay-23 {
  transition: all 1s 2.3s ease;
}

.animate-delay-24 {
  transition: all 1s 2.4s ease;
}

.animate-delay-25 {
  transition: all 1s 2.5s ease;
}

.animate-delay-26 {
  transition: all 1s 2.6s ease;
}

.animate-delay-27 {
  transition: all 1s 2.7s ease;
}

.animate-delay-28 {
  transition: all 1s 2.8s ease;
}

.animate-delay-29 {
  transition: all 1s 2.2s ease;
}

.animate-delay-30 {
  transition: all 1s 3s ease;
}

.animate-delay-35 {
  transition: all 1s 3.5s ease;
}

.animate-delay-40 {
  transition: all 1s 4s ease;
}

.animate-delay-45 {
  transition: all 1s 4.5s ease;
}

.animate-delay-50 {
  transition: all 1s 5s ease;
}

.animate-delay-55 {
  transition: all 1s 5.5s ease;
}

.animate-delay-60 {
  transition: all 1s 6s ease;
}

.animate-delay-65 {
  transition: all 1s 6.5s ease;
}

.animate-delay-70 {
  transition: all 1s 7s ease;
}

.animate-delay-75 {
  transition: all 1s 7.5s ease;
}

.animate-delay-80 {
  transition: all 1s 8s ease;
}

.animate-delay-85 {
  transition: all 1s 8.5s ease;
}

.animate-delay-90 {
  transition: all 1s 9s ease;
}

.animate-delay-95 {
  transition: all 1s 9.5s ease;
}

/* Animation Duration */

.animate-duration-02 {
  transition-duration: 0.2s;
}

.animate-duration-05 {
  transition-duration: 0.5s;
}

.animate-duration-10 {
  transition-duration: 1s;
}

.animate-duration-15 {
  transition-duration: 1.5s;
}

.animate-duration-20 {
  transition-duration: 2s;
}

.animate-duration-25 {
  transition-duration: 2.5s;
}

.animate-duration-30 {
  transition-duration: 3s;
}

.animate-duration-35 {
  transition-duration: 3.5s;
}

.animate-duration-40 {
  transition-duration: 4s;
}

.animate-duration-45 {
  transition-duration: 4.5s;
}

.animate-duration-50 {
  transition-duration: 5s;
}

.animate-duration-55 {
  transition-duration: 5.5s;
}

.animate-duration-60 {
  transition-duration: 6s;
}

.animate-duration-65 {
  transition-duration: 6.5s;
}

.animate-duration-70 {
  transition-duration: 7s;
}

.animate-duration-75 {
  transition-duration: 7.5s;
}

.animate-duration-80 {
  transition-duration: 8s;
}

.animate-duration-85 {
  transition-duration: 8.5s;
}

.animate-duration-90 {
  transition-duration: 9s;
}

.animate-duration-95 {
  transition-duration: 9.5s;
}

.animate-duration-100 {
  transition-duration: 10s;
}

/* Animation Fade */

.animate-fadeIn {
  opacity: 0;
}

.animate.active .animate-fadeIn {
  opacity: 1;
}

/* Animation Scale Up */

.animate .animate-scale-from-small {
  transform: scale(0.95);
}
.animate.active .animate-scale-from-small {
  transform: scale(1);
}

/* Animation Down */

.animate .animate-scale-from-big {
  transform: scale(1.2);
}
.animate.active .animate-scale-from-big {
  transform: scale(1);
}

/* Animation Slide Down */

.animate .animate-slideFromTop {
  top: -100px;
}
.animate.active .animate-slideFromTop {
  top: 0;
}

/* Animation Slide Up */

.animate-slideFromBottom {
  top: 50px;
}
.animate.active .animate-slideFromBottom {
  top: 0;
}

/* Animation Slide Right */

.animate .animate-slideFromLeft {
  left: -50px;
}
.animate.active .animate-slideFromLeft {
  left: 0;
}

/* Animation Slide Left */

.animate .animate-slideFromRight {
  right: -100px;
}
.animate.active .animate-slideFromRight {
  right: 0;
}

/* Animation Pulse */

@keyframes pulse {
  0% {
    transform: scale(0.95);
  }

  50% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.95);
  }
}

/* Animation Glow and Pulse */

@keyframes pulse-glow {
  0% {
    transform: scale(0.95);
    filter: drop-shadow(0 0 20px #e4198300);
  }

  20% {
    transform: scale(1.5);
    filter: drop-shadow(0 0 20px #6c25df);
  }

  100% {
    transform: scale(0.95);
    filter: drop-shadow(0 0 20px #e4198300);
  }
}

@keyframes pulse-small {
  0% {
    transform: scale(0.95);
    filter: drop-shadow(0 0 20px #e4198300);
  }

  20% {
    transform: scale(1.1);
    filter: drop-shadow(0 0 20px #6c25df);
  }

  100% {
    transform: scale(0.95);
    filter: drop-shadow(0 0 20px #e4198300);
  }
}

/* Animation Pulse */

@keyframes move-left {
  0% {
    margin-left: -20px;
  }

  50% {
    margin-left: 0px;
  }

  100% {
    margin-left: -20px;
  }
}

/* Animation Rotation */

@keyframes rotation {
  100% {
    transform: rotate(360deg);
  }
}

/* Animation Wobble */

@keyframes wobble {
  0%,
  100% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
  }

  15% {
    -webkit-transform: translateX(-32px) rotate(-10deg);
    transform: translateX(-32px) rotate(-10deg);
  }

  30% {
    -webkit-transform: translateX(calc(32px / 2)) rotate(10deg);
    transform: translateX(calc(32px / 2)) rotate(10deg);
  }

  45% {
    -webkit-transform: translateX(calc(-32px / 2)) rotate(calc(-10deg / 1.8));
    transform: translateX(calc(-32px / 2)) rotate(calc(-10deg / 1.8));
  }

  60% {
    -webkit-transform: translateX(calc(32px / 3.3)) rotate(calc(10deg / 3));
    transform: translateX(calc(32px / 3.3)) rotate(calc(10deg / 3));
  }

  75% {
    -webkit-transform: translateX(calc(-32px / 5.5)) rotate(calc(-10deg / 5));
    transform: translateX(calc(-32px / 5.5)) rotate(calc(-10deg / 5));
  }
}

/* Animation Wobble */

@keyframes wobble-small {
  0%,
  100% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
  }

  15% {
    -webkit-transform: translateX(-32px) rotate(-5deg);
    transform: translateX(-32px) rotate(-5deg);
  }

  30% {
    -webkit-transform: translateX(calc(32px / 2)) rotate(5deg);
    transform: translateX(calc(32px / 2)) rotate(5deg);
  }

  45% {
    -webkit-transform: translateX(calc(-32px / 2)) rotate(calc(-5deg / 1.8));
    transform: translateX(calc(-32px / 2)) rotate(calc(-5deg / 1.8));
  }

  60% {
    -webkit-transform: translateX(calc(32px / 3.3)) rotate(calc(5deg / 3));
    transform: translateX(calc(32px / 3.3)) rotate(calc(5deg / 3));
  }

  75% {
    -webkit-transform: translateX(calc(-32px / 5.5)) rotate(calc(-5deg / 5));
    transform: translateX(calc(-32px / 5.5)) rotate(calc(-5deg / 5));
  }
}

/* Animation Scale Fade */

@keyframes scale-fade-in {
  0% {
    transform: scale(0);
    opacity: 0;
  }

  30%,
  70% {
    transform: scale(1);
    opacity: 1;
  }

  100% {
    transform: scale(2);
    opacity: 0;
  }
}

@keyframes shine {
  0% {
    filter: brightness(1);
  }
  50% {
    filter: brightness(1.9);
  }
  100% {
    filter: brightness(1);
  }
}

@keyframes shake-bottom {
  0%,
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
  }
  10% {
    -webkit-transform: rotate(2deg);
    transform: rotate(2deg);
  }
  20%,
  40%,
  60% {
    -webkit-transform: rotate(-4deg);
    transform: rotate(-4deg);
  }
  30%,
  50%,
  70% {
    -webkit-transform: rotate(4deg);
    transform: rotate(4deg);
  }
  80% {
    -webkit-transform: rotate(-2deg);
    transform: rotate(-2deg);
  }
  90% {
    -webkit-transform: rotate(2deg);
    transform: rotate(2deg);
  }
}

/**
   * ----------------------------------------
   * animation shake-horizontal
   * ----------------------------------------
   */

@keyframes shake-horizontal {
  0%,
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70% {
    -webkit-transform: translateX(-0px);
    transform: translateX(-0px);
  }
  20%,
  40%,
  60% {
    -webkit-transform: translateX(2px);
    transform: translateX(2px);
  }
  80% {
    -webkit-transform: translateX(2px);
    transform: translateX(2px);
  }
  90% {
    -webkit-transform: translateX(-2px);
    transform: translateX(-2px);
  }
}

@media (max-width: 980px) {
  /*disable animations for small displays*/
  .animate-elem {
    transition: all;
    transition-duration: 0s;
  }

  .animate .animate-fadeIn {
    opacity: 1;
  }

  .animate .animate-slideFromTop {
    top: 0;
  }

  .animate .animate-slideFromBottom {
    top: 0;
  }

  .animate .animate-slideFromLeft {
    left: 0;
  }

  .animate .animate-slideFromRight {
    right: 0;
  }

  .animate .animate-scale-from-small {
    transform: scale(1);
  }

  .animate .animate-scale-from-big {
    transform: scale(1);
  }
}
