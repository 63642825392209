/* ==========================================================================
   Responsive styles
   ========================================================================== */

/* Wide Screens */

@media (min-width: 1920px) and (max-width: 2560px) {
  .container {
    max-width: 1920px;
    margin: 0px auto;
  }
}

/* Laptop */

@media (max-device-width: 1600px) {
  h1 {
    font-size: 48px;
  }

  h2 {
    font-size: 42px;
  }

  h3 {
    font-size: 28px;
    line-height: 120%;
  }

  h4 {
    margin-bottom: 0px;
  }

  p {
    font-size: 16px;
  }

  .text-large {
    font-size: 16px;
    line-height: 140%;
  }

  .medium-title {
    font-size: 38px;
    margin-bottom: 0px;
  }

  .nav .btn {
    font-size: 11px;
  }

  .btn {
    font-size: 14px;
  }

  .features {
    width: 50%;
    padding: 0px;
  }

  .feature-item h4 {
    font-size: 22px;
  }
}

/* Tablet Landscape */

@media (min-width: 1025px) and (max-width: 1330px) {
}

/* Tablet Landscape */

@media (min-width: 769px) and (max-width: 1024px) {
  h1 {
    font-size: 36px;
  }

  h2 {
    font-size: 32px;
    margin-bottom: 10px;
  }
  h3 {
    font-size: 28px;
    line-height: 120%;
  }

  .main section {
    height: inherit;
  }

  .container-tiny {
    margin: 0 auto;
    max-width: 800px;
  }

  .font-secondary {
    font-family: var(--font-secondary);
    font-size: 24px;
    margin-bottom: 20px;
  }

  .label {
    font-family: var(--font-secondary);
    font-size: 14px;
    margin-bottom: 20px;
  }

  .feature-item {
    height: 120px;
  }

  .card {
    min-height: 120px;
  }

  .feature-item h4 {
    font-size: 16px;
  }

  .feature-item img {
    width: 70px;
  }

  .funding-vid,
  .early video {
    max-width: 320px;
  }
}

/* Tablet Portrait */

@media (max-width: 768px) {
  .main section {
    padding: 5vh 0;
  }
  .container-tiny {
    width: 90%;
  }

  h1 {
    font-size: 48px !important;
    line-height: 120%;
  }

  .font-secondary {
    margin-bottom: 20px;
  }

  .w-40,
  .w-50,
  .w-60,
  .w-70 {
    width: 100%;
  }

  p {
    font-size: 16px;
    line-height: 22px;
  }

  .mobile-f-col {
    flex-direction: column;
  }

  .mobile-f-col-rev {
    flex-direction: column-reverse;
  }
  .outline-text {
    margin-bottom: -2vh;
  }

  .staking,
  .early,
  .fundraising {
    background-image: none;
  }

  .funding-vid {
    max-width: 380px;
    margin-top: 10vh;
  }

  .early video {
    max-width: 400px;
  }

  .fundraising .funding-vid {
    margin-top: 0;
  }
}

/* Mobile */

@media (max-width: 768px) and (min-width: 643px) {
  .w-40,
  .w-50,
  .w-60,
  .w-70 {
    width: 100%;
  }

  h1 {
    font-size: 54px;
    line-height: 120%;
  }

  h2 {
    font-size: 42px;
  }

  .hero-vid {
    position: absolute;
    width: 250%;
    top: 2%;
    right: -32%;
  }

  .hero {
    align-items: flex-end;
  }

  .big-type {
    font-size: 20px;
    line-height: 28px;
  }

  .hero-title {
    margin-bottom: 10vh;
  }
  .rotate-text {
    width: 100%;
  }

  .btn {
    padding: 15px 30px;
  }
}

@media (max-width: 642px) {
  html,
  body {
    background: none;
  }

  h1 {
    font-size: 36px !important;
    line-height: 110% !important;
  }

  h2 {
    font-size: 32px;
  }

  h3 {
    font-size: 28px;
    line-height: 110%;
    margin-bottom: 20px;
  }

  .font-secondary {
    font-size: 22px;
    line-height: 150%;
  }

  .container-small {
    width: 90%;
    margin: 0 auto;
    max-width: 1920px;
    position: relative;
  }

  .slick-slider {
    margin-top: 30px;
  }

  .slick-dots {
    width: 100% !important;
    left: 0 !important;
  }

  .investment-feature .item-1 {
    left: 20px;
  }

  .investment-feature .item-2 {
    width: 100px;
    height: 100px;
    right: -20px;
  }

  .label {
    width: 80%;
    font-size: 16px;
  }

  .feature-item h4 {
    font-size: 18px;
  }

  .community .container:nth-child(2) {
    display: grid;
    grid-template-columns: 48% 48%;
  }

  .btn-container {
    flex-direction: column;
    align-items: center;
  }

  .btn-container .btn:first-child {
    margin-right: 0px;
    margin-bottom: 20px;
  }

  .social-item {
    margin-bottom: 20px;
  }

  .liquid-vesting video {
    max-width: 200px;
    margin-bottom: 5vh;
  }

  .fundraising .funding-vid {
    max-width: 250px;
  }
}

/* Mobile Small */
@media (max-width: 375px) {
  h1 {
    font-size: 36px !important;
    line-height: 110% !important;
  }

  h2 {
    font-size: 28px;
    margin-bottom: 10px;
  }

  .feature-item img {
    width: 60px;
  }

  .feature-item .card-front {
    padding: 15px 20px;
  }

  .feature-item h4 {
    font-size: 18px;
  }

  .social-item {
    width: 90px;
    height: 90px;
  }

  .font-secondary {
    margin-bottom: 0px;
  }

  .liquid-vesting .w-50:first-child img {
    max-height: 300px;
  }
}
