/* ==========================================================================
   Early stage
   ========================================================================== */
.early {
  height: inherit;
  position: relative;
  background: url("/src/images/staking-hero.jpg") no-repeat;
  background-size: cover;
  background-position: center;
}

.early::before {
  content: "";
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 20%;
  display: block;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  );
}

.early .w-50:nth-child(2) img {
  width: 100%;
}

.early video {
  max-width: 680px;
}

.early .hero-content {
  max-width: 800px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.liquid-vesting {
  position: relative;
}

.liquid-vesting::before {
  content: "";
  position: absolute;
  top: 0;
  width: 100%;
  height: 40%;
  display: block;
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  );
}

.liquid-vesting video {
  max-width: 360px;
}

.gradient-text {
  font-size: 32px;
  background: linear-gradient(90deg, #7d5eaf 2.37%, #8da6ff 76.86%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-top: 0px;
  display: block;
}

.early h1,
.liquid-vesting h1 {
  margin-bottom: 0px;
}

@media (max-device-width: 1600px) {
  .gradient-text {
    font-size: 24px;
    line-height: 120%;
    margin-top: 10px;
  }

  .liquid-vesting .w-50:first-child img {
    max-height: 500px;
  }

  .early video {
    max-width: 500px;
  }

  .liquid-vesting video {
    max-width: 300px;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .liquid-vesting .w-50:first-child img {
    max-height: 350px;
  }
}

@media (max-width: 768px) {
  .early .container {
    flex-direction: column-reverse;
    text-align: center;
  }

  .early .w-50 img {
    width: 60%;
    margin: 10vh 0px 5% 0px;
  }

  .early .hero-content {
    align-items: center;
  }

  .liquid-vesting .container {
    flex-direction: column;
  }

  .liquid-vesting img {
    margin-bottom: 5vh;
  }

  .liquid-vesting .container .w-50 {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .liquid-vesting .max-vid {
    height: 400px;
    margin-bottom: 10vh;
  }
}
