/* ==========================================================================
   Main Styles
   ========================================================================== */

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;700&family=Oswald:wght@400&display=swap");

:root {
  --color-yellow: #f7f5bd;
  --color-blue: #4fdef8;
  --color-pink: #d2aae7;
  --color-purple: #8d73f2;
  --color-dark: #31426f;
  --color-grey: #c1c1d3;

  --color-white: #fff;
  --color-black: #0b0022;

  --color-messageError: #ffc4c4;
  --color-messageSuccess: #00fff5;
  --color-fieldError: #eb5757;

  --font-title: "Inter", sans-serif;
  --font-text: "Inter", sans-serif;
  --font-secondary: "Oswald", sans-serif;
}

html,
body {
  padding: 0;
  margin: 0;
  font-size: 16px;
  line-height: 20px;
  font-family: var(--font-text);
  letter-spacing: 0;
  color: var(--color-dark);
  background: url("/src/images/background.svg");
  background-size: 100%;
}

body.fixed {
  position: fixed;
  width: 100%;
  height: 100%;
}

.main {
  z-index: 2;
  position: relative;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-title);
  font-weight: 700;
  margin-top: 0px;
}

h1 {
  font-size: 62px;
  line-height: 120%;
  margin-bottom: 30px;
}

h2 {
  font-size: 52px;
  line-height: 120%;
  margin-bottom: 30px;
}

h3 {
  font-size: 36px;
  line-height: 100%;
}

h4 {
  font-size: 28px;
  line-height: 120%;
  margin-bottom: 20px;
}

h5 {
  margin-bottom: 10px;
  font-size: 24px;
  line-height: 120%;
}

p {
  font-size: 18px;
  line-height: 140%;
}

.large-text {
  font-size: 24px;
  line-height: 30px;
}

.text-large {
  font-size: 20px;
  line-height: 140%;
}

.text-small {
  font-size: 16px;
  line-height: 20px;
}

.text-upper {
  text-transform: uppercase;
}

.font-secondary {
  font-family: var(--font-secondary);
}

.glow {
  text-shadow: 0px 0px 15px rgba(255, 255, 255, 0.4);
}

.main-font {
  font-family: var(--font-title);
}

a {
  text-decoration: none;
  color: var(--color-dark);
  transition: all 0.3s;
}

ul {
  padding: 0;
  margin: 0;
}

.divider {
  height: 2px;
  width: 50px;
  background: linear-gradient(
    281deg,
    #2b7aff -14.77%,
    #ff78f1 46.2%,
    #00fff7 109.25%
  );
}

.div-vertical {
  width: 1px;
  background: var(--color-purple);
}

.underline {
  position: relative;
}

.underline:after {
  content: "";
  position: absolute;
  top: 90%;
  width: 100%;
  aspect-ratio: 3 / 1;
  left: 50%;
  transform: translate(-50%, 0);
  height: 20px;
  background: url("/src/images/underline.svg") no-repeat;
  border-radius: 99px;
  background-size: cover;
}

.text-gradient {
  background: linear-gradient(
    281deg,
    #2b7aff -14.77%,
    #ff78f1 46.2%,
    #00fff7 109.25%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: none;
}

.small-title {
  font-size: 32px;
}

.medium-title {
  font-size: 48px;
  margin-bottom: 20px;
}

.color-purple {
  color: var(--color-purple) !important;
}

.whitespace-5per {
  padding: 5%;
  box-sizing: border-box;
}

/* .text-shadow {
  text-shadow: 0px 0px 16px var(--color-white);
} */

.label {
  border-radius: 30px;
  font-size: 14px;
  font-style: normal;
  text-transform: uppercase;
  font-weight: 400;
  padding: 8px;
  display: flex;
  width: 200px;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  color: var(--color-white);
}

.gradient-pink {
  background: linear-gradient(270deg, #d2aae7 0%, #e7c7aa 100%);
}

.gradient-purple {
  background: linear-gradient(270deg, #8d73f2 0.48%, #ffc1db 100%);
}

.gradient-yellow {
  background: #f7f5bd;
}

.slick-arrow {
  background: var(--color-white);
  padding: 15px;
  border-radius: 99px;
  width: 50px;
  height: 50px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  border-radius: 99px;
  transition: 0.3s all;
}

.slick-next:before,
.slick-prev:before {
  font-family: "colony";
  font-size: 20px;
  line-height: 1;
  opacity: 0.75;
  color: var(--color-dark);
}

.slick-prev:before {
  content: "\e907";
}

.slick-next:before {
  content: "\e904";
}

.slick-next:focus,
.slick-next:hover,
.slick-prev:focus,
.slick-prev:hover {
  opacity: 1;
  background: var(--color-purple);
}

.btn-container {
  display: flex;
  margin-top: 30px;
}

.btn-container .btn:first-child {
  margin-right: 20px;
}

.max-vid {
  max-width: 400px;
}

video {
  clip-path: inset(1px 1px);
}
