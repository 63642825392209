/* ==========================================================================
   Footer
   ========================================================================== */

.footer {
  background: radial-gradient(
    87.2% 87.2% at 50% 100%,
    #8d73f2 0%,
    #0d6171 51.56%,
    #0e3b44 78.65%,
    #1d2128 100%
  );
  padding: 5vh 0;
  color: var(--color-white);
}

.footer .container {
  display: grid;
  grid-template-columns: 20% 20% 20% 30%;
  column-gap: 3.33%;
  max-width: 1920px;
}

.footer a {
  color: var(--color-white);
}

.footer a:hover {
  opacity: 1;
}

.footer-col {
  padding: 30px 0px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.logo-footer {
  max-width: 240px;
}

.footer-col h4 {
  margin: 0px 0px 10px 0px;
  text-transform: uppercase;
  font-size: 20px;
}

.footer-col ul {
  margin-bottom: 20px;
}

.footer-col ul li {
  list-style: none;
  margin-bottom: 10px;
}

.footer-col ul li a {
  cursor: pointer;
  display: flex;
  margin-left: -20px;
}

.footer-col ul li a:hover {
  padding-left: 20px;
}

.footer-col ul li a::before {
  content: "\e906";
  font-family: "colony";
  color: var(--color-purple);
  position: relative;
  display: flex;
  margin-right: 10px;
  opacity: 0;
  transition: 0.3s all;
  font-size: 12px;
}

.footer-col ul li a:hover::before {
  opacity: 1;
}

.privacy {
  font-size: 10px;
  line-height: 120%;
}

.social {
  display: flex;
  justify-content: space-around;
}

.social a {
  font-size: 42px;
  margin-right: 10px;
  cursor: pointer;
  color: var(--color-dark);
}

.social a:hover {
  color: var(--color-white);
  filter: drop-shadow(0px 0px 15px rgba(255, 255, 255, 0.6));
}

.newsletter input {
  width: 70%;
  font-size: 16px;
}

.newsletter button {
  margin-left: -50px;
}

.supported {
  display: flex;
  align-items: center;
  margin-top: 20px;
  border-top: 1px solid #404652;
  padding-top: 10px;
}

.supported p {
  font-size: 12px;
}

.footer-social {
  max-width: 650px;
  margin: 0px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 30px;
}

.footer-social h5 {
  font-family: var(--font-secondary);
  text-transform: uppercase;
  font-size: 18px;
  letter-spacing: 1px;
  margin: 0px 20px 0px 0px;
}

.footer .title {
  font-family: var(--font-secondary);
  letter-spacing: 1px;
  margin-bottom: 20px;
}

.div {
  height: 1px;
  width: 100%;
  background: linear-gradient(
    90deg,
    rgba(69, 64, 86, 0.27) 0%,
    #454056 47.61%,
    rgba(69, 64, 86, 0) 91.41%
  );
}

/**** Responsive ****/

@media (max-width: 768px) {
  .footer {
    display: grid;
    grid-template-columns: 100%;
    justify-content: space-between;
  }

  .footer .container {
    grid-template-columns: 48% 48%;
  }
}

@media (max-width: 642px) {
  .footer {
    display: grid;
    grid-template-columns: 100%;
    justify-content: space-between;
  }

  .footer .title {
    text-align: center;
  }

  .footer-col,
  .footer-col ul li a {
    justify-content: center;
  }

  .footer .container {
    grid-template-columns: 100%;
  }

  .footer-social {
    flex-direction: column;
  }

  .social {
    margin-top: 20px;
  }

  .logo-footer {
    max-width: 90%;
    margin: 0px auto;
  }

  .supported .logo-footer {
    margin: 0px;
  }

  .footer-col {
    align-items: center;
  }
}
