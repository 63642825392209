/* ==========================================================================
   Navigation
   ========================================================================== */

header {
  width: 100%;
  box-sizing: border-box;
  padding: 10px 0px;
  z-index: 9999;
  position: fixed;
  background: var(--color-white);
  box-shadow: 0px 1px 43px 0px #c7f0fd80;
}

header .logo {
  width: 20%;
  transition: 0.3s all;
}

.nav-logo {
  width: 100%;
  height: 100%;
  max-width: 140px;
}

header .logo:hover {
  transform: scale(0.95);
  filter: brightness(1.2);
}

header .nav {
  width: 80%;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

header .nav li {
  display: flex;
  align-items: center;
  font-size: 14px;
  position: relative;
  margin-right: 40px;
}

header .nav li a {
  position: relative;
  display: flex;
  align-items: center;
  font-weight: bold;
  font-family: var(--font-title);
  transition: 0.2s all;
  opacity: 1;
  z-index: 2;
}

header .nav li a:hover {
  color: var(--color-purple);
}

#submenu {
  width: 300px;
  left: calc(50% - 150px);
  opacity: 0;
  position: absolute;
  top: 0px;
  visibility: hidden;
  z-index: 1;
  border-radius: 20px;
  background: var(--color-white);
  overflow: hidden;
  padding: 10px 0px 20px 0px;
  border: 1px solid var(--color-purple);
  box-shadow: 0px 1px 43px 0px #fff;
}

li:hover ul#submenu {
  opacity: 1;
  top: 75px;
  visibility: visible;
  display: flex;
  flex-direction: column;
}

.has-children div {
  width: 200px;
  height: 70px;
  position: absolute;
  top: -10px;
  cursor: pointer;
  left: calc(50% - 100px);
  z-index: 1;
}

#submenu li {
  float: none;
  width: 100%;
  margin-right: 0px;
}

#submenu li a {
  text-transform: none;
  padding: 15px 20px;
  font-size: 14px;
  line-height: 16px;
}

.small-text {
  padding-bottom: 30px !important;
}

.small-text span {
  font-size: 12px;
  line-height: 14px;
  margin: 0px;
  position: absolute;
  padding-left: 52px;
  transition: 0.3s all;
  top: 60px;
}

#submenu li .small-text:hover span {
  padding-left: 62px;
}

#submenu li a::before {
  content: "\e906";
  font-family: "colony";
  width: 0px;
  height: 20px;
  color: var(--color-purple);
  position: relative;
  display: flex;
  margin-right: 0px;
  opacity: 0;
  transition: 0.3s all;
  background: none;
}

#submenu li a:hover::before {
  margin-right: 10px;
  opacity: 1;
}

#submenu li:first-child {
  border-bottom: 1px solid #e7dbed;
}

#submenu a:hover {
  color: var(--color-purple);
  opacity: 1;
}

.has-children a {
  cursor: pointer;
}

.has-children::after {
  content: "\e904";
  font-family: "colony";
  width: 20px;
  height: 20px;
  margin-left: 5px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s all;
  color: var(--color-purple);
  z-index: 9;
}

.has-children:hover:after {
  transform: rotate(-180deg);
  color: var(--color-blue);
}

.has-children:hover #submenu {
  animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

#submenu img {
  max-width: 42px;
  margin-right: 10px;
}

@keyframes slide-top {
  0% {
    transform: translateY(0px);
    opacity: 0;
  }
  100% {
    transform: translateY(-20px);
    opacity: 1;
  }
}

header .btn {
  font-size: 14px;
  padding: 10px 20px !important;
}

@media (max-width: 768px) {
  /*** Hamburger menu ***/

  #nav-icon {
    display: none;
    width: 40px;
    height: 45px;
    position: absolute;
    right: 25px;
    top: 12px;
    margin: 0 auto;
    transform: rotate(0deg);
    transition: 0.5s ease-in-out;
    cursor: pointer;
    z-index: 2;
  }

  header span {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    background: var(--color-white);
    border-radius: 9px;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: 0.25s ease-in-out;
  }

  header.close .menu span:nth-child(1) {
    top: 0;
    transition: 0.3s all;
  }

  header.close .menu span:nth-child(2) {
    top: 10px;
    transition: 0.3s all;
  }

  header.close .menu span:nth-child(3) {
    top: 20px;
    transition: 0.3s all;
  }

  header.open .menu span:nth-child(1) {
    top: 10px;
    transform: rotate(45deg);
  }

  header.open .menu span:nth-child(2) {
    opacity: 0;
    left: -60px;
  }

  header.open .menu span:nth-child(3) {
    top: 10px;
    transform: rotate(320deg);
  }

  header.open .nav {
    width: 100vw;
  }

  header.open .nav {
    opacity: 1;
  }

  header .menu {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 30px;
    height: 21px;
    right: 30px;
    position: fixed;
    top: 22px;
    z-index: 9;
  }

  header .menu span {
    width: 100%;
    height: 3px;
    background: var(--color-dark);
  }

  header .logo {
    width: 200px;
  }

  header .nav {
    position: fixed;
    top: 0;
    right: 0;
    width: 0vw;
    height: 100vh;
    transition: width 0.4s;
    z-index: 9;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0;
    padding: 0;
    background: linear-gradient(
      32deg,
      #c6a1e8 -2.62%,
      #b6effa 28.2%,
      #fdfcee 58.4%
    );
    opacity: 0;
    transition: 0.3s all;
  }

  header .nav li {
    margin: 20px 0px;
  }

  header #submenu li {
    margin: 10px 0px;
  }

  header li a {
    font-size: 18px;
  }
  .nav .btn {
    font-size: 16px;
    margin-top: 20px;
  }

  .nav::before {
    content: url("../images/logo-mark.svg");
    display: block;
    width: 100px;
    height: 100px;
    position: relative;
    margin-bottom: 30px;
  }

  header .has-children {
    margin-left: 20px !important;
  }

  .nav-logo {
    max-width: 200px;
  }

  .nav .btn {
    font-size: 16px !important;
    padding: 16px 40px !important;
  }

  .has-children {
    z-index: 999;
  }

  #submenu {
    padding: 0px 0px 20px 0px;
  }

  .small-text span {
    top: 60px;
    padding-left: 72px;
  }
}
