@font-face {
  font-family: "colony";
  src: url("../fonts/colony.eot");
  src: url("../fonts/colony.eot") format("embedded-opentype"),
    url("../fonts/colony.ttf") format("truetype"),
    url("../fonts/colony.woff") format("woff"),
    url("../fonts/colony.svg") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "colony" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-ar-angle:before {
  content: "\e900";
}
.icon-ar-down:before {
  content: "\e901";
}
.icon-ar-left:before {
  content: "\e902";
}
.icon-ar-right:before {
  content: "\e903";
}
.icon-arrow-down:before {
  content: "\e904";
}
.icon-arrow-left:before {
  content: "\e905";
}
.icon-arrow-right:before {
  content: "\e906";
}
.icon-arrow-up:before {
  content: "\e907";
}
.icon-ar-top:before {
  content: "\e908";
}
.icon-discord:before {
  content: "\e909";
}
.icon-linkedin:before {
  content: "\e90a";
}
.icon-medium:before {
  content: "\e90b";
}
.icon-telegram:before {
  content: "\e90c";
}
.icon-twitter:before {
  content: "\e90d";
}
.icon-youtube:before {
  content: "\e90e";
}
