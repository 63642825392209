/* ==========================================================================
   User Interface
   ========================================================================== */

/* Button General */

.btn {
  display: flex;
  opacity: 1;
  padding: 20px 40px;
  text-align: center;
  font-weight: 500;
  text-transform: uppercase;
  font-family: var(--font-secondary);
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
  border-radius: 99px;
  overflow: hidden;
  transition: all 0.4s;
}

.btn::after {
  content: "\e903";
  font-family: "colony";
  width: 20px;
  height: 20px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s all;
  font-size: 12px;
}

.btn:hover:after {
  animation: diagonal-move-anim 0.5s linear;
  animation-iteration-count: infinite;
}

button {
  border: none;
}

.btn-light {
  border: none;
  color: var(--color-dark);
  background: transparent;
}

.btn-light:hover {
  background: linear-gradient(45deg, #f7f5bd, #4fdef8, #d2aae7, #8d73f2);
  box-shadow: 0px 0px 20px #9512ff62;
}

.btn-light:before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 50px;
  padding: 2px;
  background: linear-gradient(45deg, #f7f5bd, #4fdef8, #d2aae7, #8d73f2);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

.btn-full {
  color: var(--color-white);
  background: linear-gradient(
    270deg,
    #f7f5bd 0%,
    #4fdef8 27.33%,
    #d2aae7 49.37%,
    #8d73f2 96.41%
  );
}

.btn-full:hover {
  box-shadow: 0px 0px 20px #00fff762;
}

.btn-disabled {
  color: var(--color-grey);
  background: transparent;
  border: 2px solid var(--color-grey);
}

.btn-disabled:hover {
  box-shadow: none;
}

@keyframes diagonal-move-anim {
  0% {
    transform: translate(0, 0);
  }

  50% {
    transform: translate(3px, 0px);
  }

  100% {
    transform: translate(0, 0);
  }
}

/* Arrow Button */

.arrow-button {
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50px;
  position: relative;
}

.arrow-button:before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 50px;
  padding: 3px;
  background: linear-gradient(45deg, #f7f5bd, #4fdef8, #d2aae7, #8d73f2);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

.arrow-button:hover {
  background: var(--color-white);
}

.arrow-button:hover span {
  color: var(--color-black);
}

/* Form Inputs */

input {
  border-radius: 99px;
}

input,
textarea {
  border: 2px solid var(--color-white);
  padding: 10px 20px;
  background: none;
  color: #ffffff;
  font-family: var(--font-text);
  outline: none;
  box-sizing: border-box;
}

textarea {
  height: 150px;
  border-radius: 8px;
}

::placeholder {
  color: #2d616a;
}

/* Error Messages */

.message-error {
  color: var(--color-messageError);
}

.message-success {
  color: var(--color-messageSuccess);
}

form .field.field-error {
  border-color: var(--color-fieldError);
}

form.form-loading button .icon {
  display: inline-block;
}

form.form-loading button .text {
  display: none;
}
