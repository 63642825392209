/* ==========================================================================
   Basic Layout
   ========================================================================== */

:root {
  --white-100: #ecebe7;
  --white-200: #f0eee7;
  --white-300: #f1efea;
  --white-400: #f3f1ec;
  --white-500: #f4f2ee;
  --white-600: #f6f4f1;
  --white-700: #f8f6f3;
  --white-800: #f9f8f5;
  --white-900: #fbfaf8;
  --white-1000: #fcfbfa;
  --white-1100: #fefdfd;
  --white-1200: #ffffff;

  --grey-100: #121212;
  --grey-200: #262627;
  --grey-300: #333333;
  --grey-400: #3f3e3e;
  --grey-500: #4f4f4f;
  --grey-600: #828282;
  --grey-700: #bdbdbd;
  --grey-800: #d6d6d6;
  --grey-900: #dbdbdb;
  --grey-1000: #dedede;
  --grey-1100: #e4e3e3;
  --grey-1200: #f2f2f2;

  --grey-cold-100: #1a1b1f;
  --grey-cold-200: #24262e;
  --grey-cold-300: #2e303a;
  --grey-cold-400: #414456;
  --grey-cold-500: #66697b;
  --grey-cold-600: #6f7283;
  --grey-cold-700: #9c9fad;
  --grey-cold-800: #b1b3bd;
  --grey-cold-900: #c1c4cf;
  --grey-cold-1000: #d5d6dd;
  --grey-cold-1100: #d5d6dd;
  --grey-cold-1200: #dfe0e4;

  --red-100: #770000;
  --red-200: #ad0808;
  --red-300: #b92222;
  --red-400: #e34444;
  --red-500: #eb5757;
  --red-600: #ff7575;
  --red-700: #ff9a9a;
  --red-800: #ffadad;
  --red-900: #ffc9c9;
  --red-1000: #ffd8d8;
  --red-1100: #ffe3e3;
  --red-1200: #ffebeb;

  --blue-100: #00132c;
  --blue-200: #011e44;
  --blue-300: #052c60;
  --blue-400: #11478f;
  --blue-500: #1d69d0;
  --blue-600: #2f80ed;
  --blue-700: #2d9cdb;
  --blue-800: #52bcf9;
  --blue-900: #56ccf2;
  --blue-1000: #89e2ff;
  --blue-1100: #b5edff;
  --blue-1200: #d8f5ff;

  --green-100: #002510;
  --green-200: #003316;
  --green-300: #02451f;
  --green-400: #055126;
  --green-500: #167740;
  --green-600: #219653;
  --green-700: #26a75d;
  --green-800: #35bf70;
  --green-900: #50d78a;
  --green-1000: #84f8b6;
  --green-1100: #acfdcf;
  --green-1200: #d2fae3;

  --yellow-100: #632e00;
  --yellow-200: #9a4800;
  --yellow-300: #c15f07;
  --yellow-400: #de812f;
  --yellow-500: #f2994a;
  --yellow-600: #eabb2c;
  --yellow-700: #f2c94c;
  --yellow-800: #ffdd76;
  --yellow-900: #ffe492;
  --yellow-1000: #fdeab2;
  --yellow-1100: #fff5d6;
  --yellow-1200: #fffaec;

  --purple-100: #2b0151;
  --purple-200: #480d7e;
  --purple-300: #6923ab;
  --purple-400: #9b51e0;
  --purple-500: #9b51e0;
  --purple-600: #bb6bd9;
  --purple-700: #cc83e8;
  --purple-800: #e5a9fc;
  --purple-900: #efc3ff;
  --purple-1000: #f4d4ff;
  --purple-1100: #f8e2ff;
  --purple-1200: #faf2fc;
}

/* Color Palette */

.white-100 {
  color: var(--white-100);
}
.white-200 {
  color: var(--white-200);
}
.white-300 {
  color: var(--white-300);
}
.white-400 {
  color: var(--white-400);
}
.white-500 {
  color: var(--white-500);
}
.white-600 {
  color: var(--white-600);
}
.white-700 {
  color: var(--white-700);
}
.white-800 {
  color: var(--white-800);
}
.white-900 {
  color: var(--white-900);
}
.white-1000 {
  color: var(--white-1000);
}
.white-1100 {
  color: var(--white-1100);
}
.white-1200 {
  color: var(--white-1200);
}

.grey-100 {
  color: var(--grey-100);
}
.grey-200 {
  color: var(--grey-200);
}
.grey-300 {
  color: var(--grey-300);
}
.grey-400 {
  color: var(--grey-400);
}
.grey-400 {
  color: var(--grey-400);
}
.grey-500 {
  color: var(--grey-500);
}
.grey-600 {
  color: var(--grey-600);
}
.grey-700 {
  color: var(--grey-700);
}
.grey-800 {
  color: var(--grey-800);
}
.grey-900 {
  color: var(--grey-900);
}
.grey-1000 {
  color: var(--grey-1000);
}
.grey-1100 {
  color: var(--grey-1100);
}
.grey-1200 {
  color: var(--grey-1200);
}

.grey-cold-100 {
  color: var(--grey-cold-100);
}
.grey-cold-200 {
  color: var(--grey-cold-200);
}
.grey-cold-300 {
  color: var(--grey-cold-300);
}
.grey-cold-400 {
  color: var(--grey-cold-400);
}
.grey-cold-500 {
  color: var(--grey-cold-500);
}
.grey-cold-600 {
  color: var(--grey-cold-600);
}
.grey-cold-700 {
  color: var(--grey-cold-700);
}
.grey-cold-800 {
  color: var(--grey-cold-800);
}
.grey-cold-900 {
  color: var(--grey-cold-900);
}
.grey-cold-1000 {
  color: var(--grey-cold-1000);
}
.grey-cold-1100 {
  color: var(--grey-cold-1100);
}
.grey-cold-1200 {
  color: var(--grey-cold-1200);
}

.green-100 {
  color: var(--green-100);
}
.green-200 {
  color: var(--green-200);
}
.green-300 {
  color: var(--green-300);
}
.green-400 {
  color: var(--green-400);
}
.green-500 {
  color: var(--green-500);
}
.green-600 {
  color: var(--green-600);
}
.green-700 {
  color: var(--green-700);
}
.green-800 {
  color: var(--green-800);
}
.green-900 {
  color: var(--green-900);
}
.green-1000 {
  color: var(--green-1000);
}
.green-1100 {
  color: var(--green-1100);
}
.green-1200 {
  color: var(--green-1200);
}

.blue-100 {
  color: var(--blue-100);
}
.blue-200 {
  color: var(--blue-200);
}
.blue-300 {
  color: var(--blue-300);
}
.blue-400 {
  color: var(--blue-400);
}
.blue-500 {
  color: var(--blue-500);
}
.blue-600 {
  color: var(--blue-600);
}
.blue-700 {
  color: var(--blue-700);
}
.blue-800 {
  color: var(--blue-800);
}
.blue-900 {
  color: var(--blue-900);
}
.blue-1000 {
  color: var(--blue-1000);
}
.blue-1100 {
  color: var(--blue-1100);
}
.blue-1200 {
  color: var(--blue-1200);
}

.yellow-100 {
  color: var(--yellow-100);
}
.yellow-200 {
  color: var(--yellow-200);
}
.yellow-300 {
  color: var(--yellow-300);
}
.yellow-400 {
  color: var(--yellow-400);
}
.yellow-500 {
  color: var(--yellow-500);
}
.yellow-600 {
  color: var(--yellow-600);
}
.yellow-700 {
  color: var(--yellow-700);
}
.yellow-800 {
  color: var(--yellow-800);
}
.yellow-900 {
  color: var(--yellow-900);
}
.yellow-1000 {
  color: var(--yellow-1000);
}
.yellow-1100 {
  color: var(--yellow-1100);
}
.yellow-1200 {
  color: var(--yellow-1200);
}

.purple-100 {
  color: var(--purple-100);
}
.purple-200 {
  color: var(--purple-200);
}
.purple-300 {
  color: var(--purple-300);
}
.purple-400 {
  color: var(--purple-400);
}
.purple-500 {
  color: var(--purple-500);
}
.purple-600 {
  color: var(--purple-600);
}
.purple-700 {
  color: var(--purple-700);
}
.purple-800 {
  color: var(--purple-800);
}
.purple-900 {
  color: var(--purple-900);
}
.purple-1000 {
  color: var(--purple-1000);
}
.purple-1100 {
  color: var(--purple-1100);
}
.purple-1200 {
  color: var(--purple-1200);
}

.red-100 {
  color: var(--red-100);
}
.red-200 {
  color: var(--red-200);
}
.red-300 {
  color: var(--red-300);
}
.red-400 {
  color: var(--red-400);
}
.red-500 {
  color: var(--red-500);
}
.red-600 {
  color: var(--red-600);
}
.red-700 {
  color: var(--red-700);
}
.red-800 {
  color: var(--red-800);
}
.red-900 {
  color: var(--red-900);
}
.red-1000 {
  color: var(--red-1000);
}
.red-1100 {
  color: var(--red-1100);
}
.red-1200 {
  color: var(--red-1200);
}

/* Background */

.bg-white-100 {
  background-color: var(--white-100);
}
.bg-white-200 {
  background-color: var(--white-200);
}
.bg-white-300 {
  background-color: var(--white-300);
}
.bg-white-400 {
  background-color: var(--white-400);
}
.bg-white-500 {
  background-color: var(--white-500);
}
.bg-white-600 {
  background-color: var(--white-600);
}
.bg-white-700 {
  background-color: var(--white-700);
}
.bg-white-800 {
  background-color: var(--white-800);
}
.bg-white-900 {
  background-color: var(--white-900);
}
.bg-white-1000 {
  background-color: var(--white-1000);
}
.bg-white-1100 {
  background-color: var(--white-1100);
}
.bg-white-1200 {
  background-color: var(--white-1200);
}

.bg-grey-100 {
  background-color: var(--grey-100);
}
.bg-grey-200 {
  background-color: var(--grey-200);
}
.bg-grey-300 {
  background-color: var(--grey-300);
}
.bg-grey-400 {
  background-color: var(--grey-400);
}
.bg-grey-400 {
  background-color: var(--grey-400);
}
.bg-grey-500 {
  background-color: var(--grey-500);
}
.bg-grey-600 {
  background-color: var(--grey-600);
}
.bg-grey-700 {
  background-color: var(--grey-700);
}
.bg-grey-800 {
  background-color: var(--grey-800);
}
.bg-grey-900 {
  background-color: var(--grey-900);
}
.bg-grey-1000 {
  background-color: var(--grey-1000);
}
.bg-grey-1100 {
  background-color: var(--grey-1100);
}
.bg-grey-1200 {
  background-color: var(--grey-1200);
}

.bg-grey-cold-100 {
  background-color: var(--grey-cold-100);
}
.bg-grey-cold-200 {
  background-color: var(--grey-cold-200);
}
.bg-grey-cold-300 {
  background-color: var(--grey-cold-300);
}
.bg-grey-cold-400 {
  background-color: var(--grey-cold-400);
}
.bg-grey-cold-500 {
  background-color: var(--grey-cold-500);
}
.bg-grey-cold-600 {
  background-color: var(--grey-cold-600);
}
.bg-grey-cold-700 {
  background-color: var(--grey-cold-700);
}
.bg-grey-cold-800 {
  background-color: var(--grey-cold-800);
}
.bg-grey-cold-900 {
  background-color: var(--grey-cold-900);
}
.bg-grey-cold-1000 {
  background-color: var(--grey-cold-1000);
}
.bg-grey-cold-1100 {
  background-color: var(--grey-cold-1100);
}
.bg-grey-cold-1200 {
  background-color: var(--grey-cold-1200);
}

.bg-green-100 {
  background-color: var(--green-100);
}
.bg-green-200 {
  background-color: var(--green-200);
}
.bg-green-300 {
  background-color: var(--green-300);
}
.bg-green-400 {
  background-color: var(--green-400);
}
.bg-green-500 {
  background-color: var(--green-500);
}
.bg-green-600 {
  background-color: var(--green-600);
}
.bg-green-700 {
  background-color: var(--green-700);
}
.bg-green-800 {
  background-color: var(--green-800);
}
.bg-green-900 {
  background-color: var(--green-900);
}
.bg-green-1000 {
  background-color: var(--green-1000);
}
.bg-green-1100 {
  background-color: var(--green-1100);
}
.bg-green-1200 {
  background-color: var(--green-1200);
}

.bg-blue-100 {
  background-color: var(--blue-100);
}
.bg-blue-200 {
  background-color: var(--blue-200);
}
.bg-blue-300 {
  background-color: var(--blue-300);
}
.bg-blue-400 {
  background-color: var(--blue-400);
}
.bg-blue-500 {
  background-color: var(--blue-500);
}
.bg-blue-600 {
  background-color: var(--blue-600);
}
.bg-blue-700 {
  background-color: var(--blue-700);
}
.bg-blue-800 {
  background-color: var(--blue-800);
}
.bg-blue-900 {
  background-color: var(--blue-900);
}
.bg-blue-1000 {
  background-color: var(--blue-1000);
}
.bg-blue-1100 {
  background-color: var(--blue-1100);
}
.bg-blue-1200 {
  background-color: var(--blue-1200);
}

.bg-yellow-100 {
  background-color: var(--yellow-100);
}
.bg-yellow-200 {
  background-color: var(--yellow-200);
}
.bg-yellow-300 {
  background-color: var(--yellow-300);
}
.bg-yellow-400 {
  background-color: var(--yellow-400);
}
.bg-yellow-500 {
  background-color: var(--yellow-500);
}
.bg-yellow-600 {
  background-color: var(--yellow-600);
}
.bg-yellow-700 {
  background-color: var(--yellow-700);
}
.bg-yellow-800 {
  background-color: var(--yellow-800);
}
.bg-yellow-900 {
  background-color: var(--yellow-900);
}
.bg-yellow-1000 {
  background-color: var(--yellow-1000);
}
.bg-yellow-1100 {
  background-color: var(--yellow-1100);
}
.bg-yellow-1200 {
  background-color: var(--yellow-1200);
}

.bg-purple-100 {
  background-color: var(--purple-100);
}
.bg-purple-200 {
  background-color: var(--purple-200);
}
.bg-purple-300 {
  color: var(--purple-300);
}
.bg-purple-400 {
  background-color: var(--purple-400);
}
.bg-purple-500 {
  background-color: var(--purple-500);
}
.bg-purple-600 {
  background-color: var(--purple-600);
}
.bg-purple-700 {
  background-color: var(--purple-700);
}
.bg-purple-800 {
  background-color: var(--purple-800);
}
.bg-purple-900 {
  background-color: var(--purple-900);
}
.bg-purple-1000 {
  background-color: var(--purple-1000);
}
.bg-purple-1100 {
  background-color: var(--purple-1100);
}
.bg-purple-1200 {
  background-color: var(--purple-1200);
}

.bg-red-100 {
  background-color: var(--red-100);
}
.bg-red-200 {
  background-color: var(--red-200);
}
.bg-red-300 {
  background-color: var(--red-300);
}
.bg-red-400 {
  background-color: var(--red-400);
}
.bg-red-500 {
  background-color: var(--red-500);
}
.bg-red-600 {
  background-color: var(--red-600);
}
.bg-red-700 {
  background-color: var(--red-700);
}
.bg-red-800 {
  background-color: var(--red-800);
}
.red-900 {
  background-color: var(--red-900);
}
.red-1000 {
  background-color: var(--red-1000);
}
.bg-red-1100 {
  background-color: var(--red-1100);
}
.bg-red-1200 {
  background-color: var(--red-1200);
}

/* Clear styles */

.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}

* html .clearfix {
  zoom: 1;
} /* IE6 */

*:first-child + html .clearfix {
  zoom: 1;
} /* IE7 */

/* Section */

.main section {
  padding: 10vh 0;
  min-height: 80vh;
}

section {
  overflow: hidden;
}

/* Video */

video {
  width: 100%;
}

/* Text Alignment */

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

/* Containers */

.container-wide {
  width: 100%;
}

.container {
  width: 90%;
  margin: 0 auto;
  max-width: 1920px;
  position: relative;
}

.container-small {
  width: 80%;
  max-width: 1400px;
  margin: 0px auto;
}

.container-tiny {
  max-width: 1000px;
  margin: 0px auto;
}

/* Columns width */

.w-100 {
  width: 100%;
}

.w-95 {
  width: 195%;
}

.w-90 {
  width: 90%;
}

.w-85 {
  width: 85%;
}

.w-80 {
  width: 80%;
}

.w-75 {
  width: 75%;
}

.w-70 {
  width: 70%;
}

.w-65 {
  width: 65%;
}

.w-60 {
  width: 60%;
}

.w-55 {
  width: 55%;
}

.w-50 {
  width: 50%;
}

.w-45 {
  width: 45%;
}

.w-40 {
  width: 40%;
}

.w-35 {
  width: 35%;
}

.w-30 {
  width: 30%;
}

.w-25 {
  width: 25%;
}

.w-20 {
  width: 20%;
}

.w-15 {
  width: 15%;
}

.w-10 {
  width: 10%;
}

.w-5 {
  width: 5%;
}

/* Columns width */

.w-max-1200 {
  max-width: 1200px;
}

.w-max-1100 {
  max-width: 1100px;
}

.w-max-1000 {
  max-width: 1000px;
}

.w-max-900 {
  max-width: 900px;
}

.w-max-800 {
  max-width: 800px;
}

.w-max-700 {
  max-width: 700px;
}

.w-max-600 {
  max-width: 700px;
}

.w-max-500 {
  max-width: 500px;
}

.w-max-400 {
  max-width: 400px;
}

.w-max-300 {
  max-width: 300px;
}

.w-max-200 {
  max-width: 200px;
}

.w-max-100 {
  max-width: 100px;
}

/* Columns height */

.vh-100 {
  height: 100vh;
}

.vh-90 {
  height: 90vh;
}

.vh-80 {
  height: 80vh;
}

.vh-70 {
  height: 70vh;
}

.vh-60 {
  height: 60vh;
}

.vh-50 {
  height: 50vh;
}

.vh-40 {
  height: 40vh;
}

.vh-30 {
  height: 30vh;
}

.vh-20 {
  height: 20vh;
}

.vh-10 {
  height: 10vh;
}

/* Positions */

.p-relative {
  position: relative;
}

.p-absolute {
  position: absolute;
}

.p-fixed {
  position: fixed;
}

.p-sticky {
  position: sticky;
}

.p-static {
  position: static;
}

/* Display */

.d-block {
  display: block;
}

.d-inline-block {
  display: inline-block;
}

.d-flex {
  display: flex;
}

.d-none {
  display: none;
}

.d-grid {
  display: grid;
}

.d-table {
  display: table;
}

/* Z-index */

.z-1 {
  z-index: 1;
}

.z-2 {
  z-index: 2;
}

.z-3 {
  z-index: 4;
}

.z-4 {
  z-index: 4;
}

.z-5 {
  z-index: 5;
}

.z-6 {
  z-index: 6;
}

.z-7 {
  z-index: 7;
}

.z-8 {
  z-index: 8;
}

.z-9 {
  z-index: 9;
}

.z-99 {
  z-index: 99;
}

.z-999 {
  z-index: 999;
}

.z-9999 {
  z-index: 9999;
}

.z-99999 {
  z-index: 99999;
}

/* Flex Box
     ========================================================================== */

/* Flex Wrap */

.f-wrap {
  flex-wrap: wrap;
}

.f-nowrap {
  flex-wrap: nowrap;
}

/* Flex Direction */

.f-dir-row {
  flex-direction: row;
}

.f-dir-col {
  flex-direction: column;
}

.f-dir-row-reverse {
  flex-direction: row-reverse;
}

.f-dir-col-reverse {
  flex-direction: column-reverse;
}

/* Flex Content */

.f-start {
  align-content: flex-start;
}

.f-center {
  align-content: center;
}

.f-space-around {
  align-content: space-around;
}

.f-space-between {
  align-content: space-between;
}

.f-end {
  align-content: flex-end;
}

/* Flex Justify */

.f-justify-center {
  justify-content: center;
}

.f-justify-start {
  justify-content: flex-start;
}

.f-justify-end {
  justify-content: flex-end;
}

.f-justify-between {
  justify-content: space-between;
}

.f-justify-around {
  justify-content: space-around;
}

.f-justify-even {
  justify-content: space-evenly;
}

/* Flex Align */

.f-align-start {
  align-items: flex-start;
}

.f-align-end {
  align-items: flex-end;
}

.f-align-center {
  align-items: center;
}

.f-align-stretch {
  align-items: stretch;
}

/* Flex Grid */

.f-grid-half {
  grid-template-columns: 48% 48%;
  column-gap: 4%;
}

.f-grid-one-third {
  grid-template-columns: 32% 32% 32%;
  column-gap: 2%;
}

.f-grid-one-forth {
  grid-template-columns: 22.75% 22.75% 22.75% 22.75%;
  column-gap: 3%;
}

.f-grid-one-fifth {
  grid-template-columns: 18.8% 18.8% 18.8% 18.8% 18.8%;
  column-gap: 1.5%;
}

/* Space Around
     ========================================================================== */

/* Margin General */

.m-0 {
  margin: 0;
}

.m-5 {
  margin: 5px;
}

.m-10 {
  margin: 10px;
}

.m-15 {
  margin: 15px;
}

.m-20 {
  margin: 20px;
}

.m-25 {
  margin: 25px;
}

.m-30 {
  margin: 30px;
}

.m-35 {
  margin: 35px;
}

.m-40 {
  margin: 40px;
}

.m-45 {
  margin: 45px;
}

.m-50 {
  margin: 50px;
}

/* Margin Top */

.mt-0 {
  margin-top: 0px;
}

.mt-5 {
  margin-top: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-25 {
  margin-top: 25px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-35 {
  margin-top: 35px;
}

.mt-45 {
  margin-top: 45px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-100 {
  margin-top: 100px;
}

/* Margin Bottom */

.mb-0 {
  margin-bottom: 0;
}

.mb-5 {
  margin-bottom: 5px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-35 {
  margin-bottom: 35px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-45 {
  margin-bottom: 45px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-100 {
  margin-bottom: 100px;
}

/* Margin Left */

.ml-0 {
  margin-left: 0;
}

.ml-5 {
  margin-left: 5px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-15 {
  margin-left: 15px;
}

.ml-20 {
  margin-left: 20px;
}

.ml-25 {
  margin-left: 25px;
}

.ml-30 {
  margin-left: 30px;
}

.ml-35 {
  margin-left: 35px;
}

.ml-40 {
  margin-left: 40px;
}

.ml-45 {
  margin-left: 45px;
}

.ml-50 {
  margin-left: 50px;
}

.ml-100 {
  margin-left: 100px;
}

/* Margin Right */

.mr-0 {
  margin-right: 0;
}

.mr-5 {
  margin-right: 5px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-15 {
  margin-right: 15px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-25 {
  margin-right: 25px;
}

.mr-30 {
  margin-right: 30px;
}

.mr-35 {
  margin-right: 35px;
}

.mr-40 {
  margin-right: 40px;
}

.mr-45 {
  margin-right: 45px;
}

.mr-50 {
  margin-right: 50px;
}

.mr-100 {
  margin-right: 100px;
}

/* Padding General */

.p-0 {
  padding: 0;
}

.p-5 {
  padding: 5px;
}

.p-10 {
  padding: 10px;
}

.p-15 {
  padding: 15px;
}

.p-20 {
  padding: 20px;
}

.p-25 {
  padding: 25px;
}

.p-30 {
  padding: 30px;
}

.p-35 {
  padding: 35px;
}

.p-40 {
  padding: 40px;
}

.p-45 {
  padding: 45px;
}

.p-50 {
  padding: 50px;
}

.p-100 {
  padding: 100px;
}

/* Padding Top */

.pt-0 {
  padding-top: 0;
}

.pt-5 {
  padding-top: 5px;
}

.pt-5 {
  padding-top: 5px;
}

.pt-10 {
  padding-top: 10px;
}

.pt-15 {
  padding-top: 15px;
}

.pt-20 {
  padding-top: 20px;
}

.pt-25 {
  padding-top: 25px;
}

.pt-30 {
  padding-top: 30px;
}

.pt-35 {
  padding-top: 35px;
}

.pt-40 {
  padding-top: 40px;
}

.pt-45 {
  padding-top: 45px;
}

.pt-50 {
  padding-top: 50px;
}

.pt-100 {
  padding-top: 100px;
}

/* Padding Bottom */

.pb-0 {
  padding-bottom: 0;
}

.pb-5 {
  padding-bottom: 5px;
}

.pb-5 {
  padding-bottom: 5px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pb-15 {
  padding-bottom: 15px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-25 {
  padding-bottom: 25px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pb-35 {
  padding-bottom: 35px;
}

.pb-40 {
  padding-bottom: 40px;
}

.pb-45 {
  padding-bottom: 45px;
}

.pb-50 {
  padding-bottom: 50px;
}

.pb-100 {
  padding-bottom: 100px;
}

/* Padding Right */

.pr-0 {
  padding-right: 0;
}

.pr-5 {
  padding-right: 5px;
}

.pr-5 {
  padding-right: 5px;
}

.pr-10 {
  padding-right: 10px;
}

.pr-15 {
  padding-right: 15px;
}

.pr-20 {
  padding-right: 20px;
}

.pr-25 {
  padding-right: 25px;
}

.pr-30 {
  padding-right: 30px;
}

.pr-35 {
  padding-right: 35px;
}

.pr-40 {
  padding-right: 40px;
}

.pr-45 {
  padding-right: 45px;
}

.pr-50 {
  padding-right: 50px;
}

.pr-100 {
  padding-right: 100px;
}

/* Padding Left */

.pl-0 {
  padding-left: 0;
}

.pl-5 {
  padding-left: 5px;
}

.pl-5 {
  padding-left: 5px;
}

.pl-10 {
  padding-left: 10px;
}

.pl-15 {
  padding-left: 15px;
}

.pl-20 {
  padding-left: 20px;
}

.pl-25 {
  padding-left: 25px;
}

.pl-30 {
  padding-left: 30px;
}

.pl-35 {
  padding-left: 35px;
}

.pl-40 {
  padding-left: 40px;
}

.pl-45 {
  padding-left: 45px;
}

.pl-50 {
  padding-left: 50px;
}

.pl-100 {
  padding-left: 100px;
}

/* Mobile Display */

.hide-desktop {
  display: none;
}

.show-desktop {
  display: block;
}

.hide-mobile {
  display: none;
}

.show-mobile {
  display: block;
}

.m-f-dir-row-rev {
  flex-direction: row-reverse;
}

.m-f-dir-col-rev {
  flex-direction: column-reverse;
}
