/* ==========================================================================
   Homepage
   ========================================================================== */
:root {
  --color-yellow: #f7f5bd;
  --color-blue: #4fdef8;
  --color-pink: #d2aae7;
  --color-purple: #8d73f2;
  --color-dark: #31426f;

  --color-white: #fff;
  --color-black: #0b0022;

  --color-messageError: #ffc4c4;
  --color-messageSuccess: #00fff5;
  --color-fieldError: #eb5757;

  --font-title: "Inter", sans-serif;
  --font-text: "Inter", sans-serif;
}

.home {
  padding: 0px !important;
  height: 100vh;
  position: relative;
}

.home::before {
  content: "";
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 20%;
  display: block;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  z-index: 3;
}

.home .container-tiny {
  z-index: 2;
}

.home h4 {
  margin-bottom: 20px;
}

.hero-vid {
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
}

.mission {
  position: relative;
}

.mission::before {
  content: "";
  position: absolute;
  top: 0;
  width: 100%;
  height: 40%;
  display: block;
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  );
}

.mission img,
.investment-feature img {
  width: 100%;
}

.features {
  width: 44%;
  padding: 3%;
}

.feature-item {
  width: 90% !important;
  margin: 10px 5% !important;
  box-sizing: border-box;
  display: inline-block;
  perspective: 1000px; /* Perspective for the 3D effect */
  transition: 0.3s all;
  height: 150px;
  cursor: pointer;
}

.card {
  min-height: 145px;
  position: relative;
  transform-style: preserve-3d;
  transition: transform 0.6s;
}

.feature-item:hover .card {
  transform: rotateX(180deg); /* Rotate on hover to reveal the back */
}

.card-front,
.card-back {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  backface-visibility: hidden;
}

.feature-item .card-front {
  padding: 20px 40px;
  border-radius: 10px;
  background: var(--color-white);
  text-transform: uppercase;
  display: flex !important;
  align-items: center;
  cursor: pointer;
  border: 1px solid var(--color-white);
  box-shadow: 0px 0px 40px #b7f1fc;
}

.feature-item .card-back {
  border-radius: 10px;
  padding: 20px 40px;
  background: linear-gradient(
    267deg,
    #f7f5bd -11.67%,
    #d2aae7 35.61%,
    #8d73f2 103.24%
  );
  box-shadow: 0px 0px 35px 0px rgba(255, 255, 255, 0.5);
  box-sizing: border-box;
  color: var(--color-white);
  display: flex;
  flex-direction: column;
  justify-content: center;
  transform: rotateX(180deg);
}

.feature-item .card-back p {
  margin: 10px 0px 0px 0px;
}

.feature-item h4 {
  margin: 0px;
  font-size: 24px;
  font-family: var(--font-secondary);
  text-transform: uppercase;
}

.feature-item img {
  width: 100px;
  margin-right: 20px;
}

.slick-slide {
  opacity: 0.5;
}

.slick-active {
  opacity: 1;
}

.features .slick-prev {
  z-index: 9;
  left: calc(50% - 10px);
  top: 45px;
}

.features .slick-next {
  z-index: 9;
  right: inherit;
  left: calc(50% - 10px);
  top: inherit;
  bottom: -10px;
}

.social-item {
  padding: 20px;
  border: 2px solid var(--color-purple);
  border-radius: 999px;
  background: transparent;
  width: 120px;
  height: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px 10px;
  cursor: pointer;
  text-align: center;
  justify-content: center;
  font-weight: bold;
  font-size: 14px;
}

.social-item:hover {
  background: var(--color-purple);
  border-color: var(--color-purple);
  color: var(--color-white);
  filter: drop-shadow(0px 0px 42px #e0a9fd);
}

.social-item span {
  font-size: 48px;
  padding-bottom: 6px;
  color: var(--color-purple);
}

.social-item:hover span {
  color: var(--color-white);
}

.community {
  background: url("/src/images/community-bg.svg") no-repeat;
  background-position: bottom;
  min-height: inherit !important;
  padding: 0 0 15vh 0 !important;
}

.home .arrow-button {
  margin-top: 3%;
}

.homepage .btn {
  margin-top: 5% !important;
}

.investment .whitespace-5per,
.builders .whitespace-5per {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.investment-feature video {
  max-width: 600px;
  border-radius: 40px;
}

.investment-feature {
  position: relative;
}

.investment-feature .item-1 {
  position: absolute;
  width: 100px;
  height: 100px;
  top: 0;
  left: 100px;
}

.investment-feature .item-2 {
  position: absolute;
  width: 220px;
  height: 200px;
  bottom: 0;
  right: 50px;
}

.mission-vid {
  max-width: 700px;
}

@media (max-device-width: 1600px) {
  .investment-feature .item-1 {
    top: -50px;
    left: 50px;
  }

  .investment-feature .item-2 {
    right: -50px;
  }

  .mission-vid {
    max-width: 550px;
  }

  .investment-feature video {
    max-width: 450px;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .home {
    padding-top: 10vh !important;
  }

  .mission .w-50:first-child {
    width: 30%;
  }

  .mission .w-50:nth-child(2) {
    width: 70%;
  }

  .investment .w-50:first-child {
    width: 70%;
  }

  .investment .w-50:nth-child(2) {
    width: 30%;
  }
  .investment-feature .item-2 {
    right: -50px;
    width: 100px;
    object-fit: contain;
  }
}

@media (max-width: 768px) {
  .home {
    height: inherit;
    padding: 10vh 0 !important;
  }

  .mission .container,
  .builders .container {
    flex-direction: column;
    text-align: center;
  }

  .investment .container {
    flex-direction: column-reverse;
    text-align: center;
  }

  .investment .whitespace-5per,
  .builders .whitespace-5per {
    align-items: center;
  }

  .features {
    width: 90% !important;
    padding: 0px;
  }

  .mission-vid {
    max-width: 400px;
  }
}

@media (max-width: 642px) {
  .feature-item img {
    width: 80px;
    margin-right: 10px;
  }

  .feature-item h4 {
    font-size: 20px;
  }

  .social-item {
    width: 80px;
    height: 80px;
    margin: 0px auto;
  }
}
