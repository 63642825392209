/* ==========================================================================
   Fundraising
   ========================================================================== */

.fundraising {
  height: inherit;
  position: relative;
  background: url("/src/images/staking-hero.jpg") no-repeat;
  background-size: cover;
  background-position: center;
}

.fundraising::before {
  content: "";
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 20%;
  display: block;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  );
}

.fundraising img {
  width: 100%;
  max-width: 600px;
}

.fundraising-content {
  position: relative;
}

.fundraising-content::before {
  content: "";
  position: absolute;
  top: 0;
  width: 100%;
  height: 40%;
  display: block;
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  );
}

.contact .w-50:first-child {
  border-right: 1px solid var(--color-grey);
}

.contact img {
  max-width: 250px;
}

.contact h3 {
  margin-bottom: 10px;
  margin-top: 30px;
}

.contact h3,
.contact p {
  max-width: 450px;
}

.funding-vid {
  max-width: 560px;
}

@media (max-device-width: 1600px) {
  .funding-vid {
    max-width: 500px;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .contact .w-50 {
    padding: 40px;
    box-sizing: border-box;
  }
}

@media (max-width: 768px) {
  .fundraising {
    padding: 10vh 0 !important;
  }

  .fundraising .container {
    flex-direction: column-reverse;
    text-align: center;
  }

  .contact {
    flex-direction: column;
  }

  .contact .w-50:first-child {
    border-right: 0px;
    margin-bottom: 50px;
  }
}
