/* ==========================================================================
   Staking
   ========================================================================== */

.staking {
  height: inherit;
  position: relative;
  background: url("/src/images/staking-hero.jpg") no-repeat;
  background-size: cover;
  background-position: center;
}

.staking::before {
  content: "";
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 20%;
  display: block;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  );
}

.staking .hero-content {
  max-width: 600px;
}

.staking img {
  width: 100%;
}

.stake-items img {
  max-width: 200px;
}

.stake-items {
  display: grid;
  grid-template-columns: 24% 24% 24% 24%;
  column-gap: 1.33%;
}

.stake-items div {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background: var(--color-white);
  border-radius: 20px;
  box-shadow: 0px 0px 30px 0px rgba(210, 170, 231, 0.5);
  padding: 30px;
  cursor: pointer;
  transition: 0.3s all;
}

.stake-items div:hover {
  transform: scale(0.95);
}

.stake-content {
  margin-top: -5vh;
}

@media (max-device-width: 1600px) {
  .stake-items img {
    max-width: 100px;
  }
}

@media (max-width: 768px) {
  .staking .container {
    flex-direction: column-reverse;
    text-align: center;
  }

  .staking .w-50 img {
    width: 60%;
    margin: 10vh 0px 5% 0px;
  }

  .stake-content {
    margin-top: 5vh;
  }

  .stake-items {
    display: grid;
    grid-template-columns: 48% 48%;
    column-gap: 4%;
  }

  .stake-items div {
    margin-bottom: 30px;
  }
}

@media (max-width: 642px) {
  .staking {
    background: url("/src/images/hero-mobile.png") no-repeat;
    background-size: contain;
  }

  .staking video {
    margin: 5vh 0;
  }

  .stake-items {
    display: grid;
    grid-template-columns: 80%;
    justify-content: center;
  }

  .stake-items img {
    max-width: 150px;
  }
}
